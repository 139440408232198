import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PostService } from 'src/app/services/post.service';
import { Post } from '../../models/Post';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})
export class PostComponent implements OnInit {
  postText: string = "";
  submitSuccessful: boolean = false;
  @ViewChild('postTextarea') postTextarea: ElementRef;

  constructor(private postService: PostService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.postTextarea.nativeElement.focus();
  }

  preserveNewlinesInText(text: string): string {
    return text.match(/[^\r\n]+/g).join("\\n\\n");
  }

  submitPost() {
    let newPost: Post = {
      body: this.preserveNewlinesInText(this.postText),
      date: new Date()
    }
    this.postService.addPost(newPost)
      .then(post => {
        this.submitSuccessful = true;
        this.postText = "";
        this.postTextarea.nativeElement.focus();
      });
  }

}
