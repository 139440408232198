// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDxtfH5ydXrmcuRW6C2qqetVyXtbdll7HU",
    authDomain: "blog-9fe32.firebaseapp.com",
    databaseURL: "https://blog-9fe32.firebaseio.com",
    projectId: "blog-9fe32",
    storageBucket: "blog-9fe32.appspot.com",
    messagingSenderId: "130790995984",
    appId: "1:130790995984:web:305f4ab4429c22f1bbb295",
    measurementId: "G-YHRBF6N2SP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
