import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PostComponent } from './components/post/post.component';
import { ReaderComponent } from './components/reader/reader.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { READER, UNAUTH, POST } from './consts/routes.const';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo([UNAUTH]);

const routes: Routes = [
  { path: UNAUTH, component: UnauthorizedComponent },
  {
    path: "",
    redirectTo: `/${POST}`,
    pathMatch: 'full',
  },
  { 
    path: POST, 
    component: PostComponent, 
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin } 
  },
  {
    path: READER, 
    component: ReaderComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }, 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
