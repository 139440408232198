import * as firebase from 'firebase';

export const DaysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function formatDate(date: Date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  let minutesString = minutes < 10 ? '0'+minutes : minutes.toString();
  var strTime = `${hours}:${minutesString}${ampm}`;

  let month = date.getMonth() + 1;
  let dateDay = date.getDate();
  let monthString = month < 10 ? '0'+month: month.toString();
  let dateDayString = dateDay < 10 ? '0'+dateDay: dateDay.toString();
  var dateString = `${monthString}/${dateDayString}/${date.getFullYear()}, ${DaysOfWeek[date.getDay()]} ${strTime}`;
  return dateString;
}

/* 
  Must first cast the date to unknown => firestore.Timestamp => JS Date
*/
export function convertFirestoreTimestampToDate(timestamp: unknown) {
  let fireTimestamp = timestamp as firebase.firestore.Timestamp;
  return new Date(fireTimestamp.seconds * 1000);
}

export function timestampFromDate(date: Date) {
  return firebase.firestore.Timestamp.fromDate(date);
}