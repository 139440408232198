<div class="container-fluid gray-bg py-5">
  <div class="row justify-content-center py-3" *ngFor="let post of postList">
    <mat-card class="col-xl-6 col-11">
      <mat-card-header>
        <mat-card-subtitle class="large-text">{{formatDate(post.date)}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="large-text" >
        <p [innerHTML]="processBodyText(post.body)"></p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
