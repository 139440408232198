import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Post } from '../models/Post';
import { convertFirestoreTimestampToDate } from '../util/dates';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  postsCollection: AngularFirestoreCollection<Post>;
  posts: Observable<Post[]>;

  constructor(public afs: AngularFirestore) { 
    this.postsCollection = this.afs.collection('posts', ref => ref.orderBy('date', 'desc'));
    this.posts = this.retrievePostsWithIds(this.postsCollection);
  }

  private retrievePostsWithIds(postsCollection: AngularFirestoreCollection<Post>): Observable<Post[]> {
    return postsCollection.snapshotChanges().pipe(
      map(changes => changes.map(a => {
        const data = a.payload.doc.data();
        data.date = convertFirestoreTimestampToDate(data.date);
        data.id = a.payload.doc.id;
        return data;
      }))
    );
  }

  getPosts() {
    return this.posts;
  }

  async addPost(newPost: Post): Promise<Post> {
    return this.postsCollection.add(newPost)
      .then(docRef => {
        return docRef.get().then(snapshot => {
          let post = snapshot.data() as Post;
          post.date = convertFirestoreTimestampToDate(post.date);
          post.id = docRef.id;
          return post;
        })
      });
  }

}
