<div class="container-fluid py-5">
  <div *ngIf="submitSuccessful" class="row justify-content-center">
    <div class="col-xl-6 col-11">
      <span class="alert alert-success w-100">Blog post submitted!</span>
    </div>
  </div>
  <div class="row my-5 justify-content-center">
    <div class="col-xl-6 col-11 text-left text-large">
      <mat-form-field class="w-100">
        <mat-label>Just one paragraph a day</mat-label>
        <textarea matInput autofocus [(ngModel)]="postText" 
          #postTextarea
          cdkTextareaAutosize cdkAutosizeMinRows="8"></textarea>
      </mat-form-field>
      <button mat-raised-button color="primary" class="mt-5" (click)="submitPost()">Submit</button>
    </div>
  </div>
</div>