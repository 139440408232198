<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">blogger</a>
    <button class="navbar-toggler mr-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <ng-container *ngIf="user$ | async as user; else showLogin">
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="postRoute">Post</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']">
            <a class="nav-link" [routerLink]="readerRoute">Read</a>
          </li>
          <li class="nav-item">
            <button mat-raised-button color="secondary" (click)="logout()">Logout</button>
          </li>
        </ng-container>
        <ng-template #showLogin>
          <button mat-raised-button color="secondary" (click)="login()">Login</button>
        </ng-template>
      </ul>
    </div>
  </div>
</nav>