import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Observable, EMPTY } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UNAUTH, READER, POST } from 'src/app/consts/routes.const';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user$: Observable<firebase.User> = this.auth.user$;
  readerRoute: string = `/${READER}`;
  postRoute: string = `/${POST}`;

  constructor(
    private readonly auth: AuthService,
    private readonly router: Router,
  ) { }

  ngOnInit(): void {
  }

  login() {
    this.auth.loginViaGoogle()
      .pipe(take(1),
        catchError((error) => {
          console.log(error);
          return EMPTY;
        }),
      )
      .subscribe((response) => {
        if (response.user.email !== "jtotheamesfang@gmail.com") {
          this.logout();
          return;
        }
        this.router.navigate(['/']);
      });
  }

  logout() {
    this.auth.logout()
      .pipe(take(1))
      .subscribe((response) => {
        this.router.navigate([UNAUTH]);
      });
  }

}
