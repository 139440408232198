import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';
import { Post } from 'src/app/models/Post';
import { formatDate } from '../../util/dates';
import { __spreadArrays } from 'tslib';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.css']
})
export class ReaderComponent implements OnInit {
  postList: Post[];

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.postService.getPosts().subscribe(posts => {
      this.postList = posts;
    });
  }

  formatDate(date: Date): string {
    return formatDate(date);
  }

  insertBreaksForNewlines(text: string): string {
    let newText = text.replace(/\\n/g, '<br/>');
    return newText;
  }

  removeBackslashBeforeQuote(text: string): string {
    let newText = text.replace(/\\"/g, '"');
    return newText;
  }

  processBodyText(body: string): string {
    return this.removeBackslashBeforeQuote(this.insertBreaksForNewlines(body));
  }

}
